
var forEach = function (array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i]); // passes back stuff we need
    }
};

function authorOrder()
{

    let windowWidth = window.innerWidth;

    if (windowWidth <= 768) {
        const memberImgs = document.querySelectorAll('.member-image-wrap');
        forEach(memberImgs, function (index, elem) {
            const bottomCopy = elem.nextElementSibling.querySelector('.member-copy-bottom');
            if (elem.parentElement.classList.contains('member-wrap') === true) {
                bottomCopy.querySelector('.mobile-image-container').append(elem);
            }
        });
    }

    window.addEventListener('resize', function () {
        windowWidth = window.outerWidth;
        const memberImgs = document.querySelectorAll('.member-image-wrap');
        if (windowWidth <= 768) {
            forEach(memberImgs, function (index, elem) {
                const bottomCopy = elem.nextElementSibling.querySelector('.member-copy-bottom');
                if (elem.parentElement.classList.contains('member-wrap') === true) {
                    bottomCopy.querySelector('.mobile-image-container').append(elem);
                }
            });
        } else {
            forEach(memberImgs, function (index, elem) {
                if (elem.parentElement.classList.contains('member-wrap') === false) {
                    elem.closest('.member-wrap').prepend(elem);
                }
            });
        }
    });

}

export default function init()
{

    authorOrder();

}
