const
    REFERRAL_CTA_SELECTOR = '[data-ga="referral-cta"]',
    REFERRAL_CALL_SELECTOR = '[data-ga="referral-call"]',
    TAKEOVER_BANNER_HEADER_BTN = document.querySelectorAll('#takeover-notification-banner a.ref-btn'),
    TAKEOVER_BANNER_FOOTER_BTN = document.querySelectorAll('#takeover-notification-banner-footer a.ref-btn'),
    TRENDING_BANNER_BTN = document.querySelectorAll('#trending-banner a'),
    BANNER_CTA = document.querySelectorAll('.banner-cta a'),
    MEDICARE_BANNER = document.querySelectorAll('.medicare-banner a'),
    PAGETYPE_FIELD = document.getElementById('pagetype'),
    PRODUCT_BRAND = document.getElementById('productbrand'),
    CATEGORY_FIELD = document.getElementById('productcategory'),
    CUSTOM_FIELD = document.getElementById('customfield1'),
    INTERNAL_LINKS_SELECTOR = '[data-link-type="internal"]',
    EXTERNAL_LINKS_SELECTOR = '[data-link-type="external"]',
    ON_PAGE_LINKS_SELECTOR = '[data-link-type="on-page"]',
    DESKTOP_SEARCH_FORM = document.querySelector('.desktop-search'),
    MOBILE_SEARCH_FORM = document.querySelector('.mobile-search');

function triggerEventTracking() {
    // Get context of page from customfield1
    const context = CUSTOM_FIELD ? CUSTOM_FIELD.value : '',
        pageTypeValue = PAGETYPE_FIELD ? PAGETYPE_FIELD.value : 'generic',
        productBrand = PRODUCT_BRAND ? PRODUCT_BRAND.value : '',
        productCategoryValue = CATEGORY_FIELD ? CATEGORY_FIELD.value : null;

    // Get Product Category of CTA
    function productCategory($element) {
        return $element.dataset.productcategory || productCategoryValue;
    }

    function providerCID($element) {
        return $element.dataset.providercid || '0'
    }

    // Get Page Type of CTA
    function pageType($element) {
        return $element.dataset.pagetype || null;
    }

    // Get Product Name of CTA
    function productName($element) {
        return $element.dataset.productname || null;
    }

    // Get Sale Marking of CTA
    function saleCTA($element) {
        return $element.dataset.sale || 'false';
    }

    // Get Element Placement of CTA
    function elementPlacement($element) {
        return $element.dataset.elementplacement || null;
    }

    // Get CTA URL of CTA
    function ctaUrl($element) {
        return $element.href || null
    }

    // Get Index of CTA
    function providerIndex($element) {
        return $element.dataset.order || 'single'
    }

    // Get Destination Type of CTA
    function destinationType($element)
    {
        return $element.dataset.destination || 'generic'
    }

    // Get Element Clicked of CTA
    function linkText($element)
    {
        return $element.innerText || ''
    }

    // Get Element Clicked of CTA
    function elementClicked($element)
    {
        return $element.dataset.elementclicked || ''
    }

    // Check if active promotion
    function checkActivePromotion()
    {
        return document.body.classList.contains('promotion-active') || 'false'
    }

    function buildDatalayerParams($element)
    {
        return {
            'href': ctaUrl($element),
            'serviceProvider': productName($element),
            'pageType': pageType($element),
            'productCategory': productCategory($element),
            'activePromotion': saleCTA($element),
            'elementPlacement': elementPlacement($element),
            'position': providerIndex($element),
            'linkText': linkText($element),
            'providerCID': providerCID($element),
        }
    }

    function triggerClickOutAction(elementTarget)
    {
        const defaultData = {
            'event': 'cta_click_out',
            'elementClicked': 'cta button',
            'destinationType': destinationType(elementTarget),
        };
        const dataLayerData = Object.assign(defaultData, buildDatalayerParams(elementTarget));
        window.dataLayer.push(dataLayerData);

        console.log(dataLayerData);

    }

    function triggerCallClickOutAction(elementTarget)
    {
        const defaultData = {
            'event': 'cta_call_click',
            'elementClicked': 'phone link',
            'destinationType': 'provider',
        };
        const dataLayerData = Object.assign(defaultData, buildDatalayerParams(elementTarget));
        window.dataLayer.push(dataLayerData);

        console.log(dataLayerData);

    }

    function triggerInternalExternalClick(elementTarget, linkType)
    {

        const eventAction = linkType === 'internal' ? 'internal_link_click' : 'external_link_click';
        const defaultData = {
            'event': eventAction,
            'elementPlacement': elementPlacement(elementTarget),
            'elementClicked': elementClicked(elementTarget),
            'pageType': pageTypeValue,
            'position': providerIndex(elementTarget),
            'href': ctaUrl(elementTarget),
            'linkText': linkText(elementTarget),
        };
        window.dataLayer.push(defaultData);
        console.log(defaultData);
    }

    function triggerOnPageClick(elementTarget)
    {

        const defaultData = {
            'event': 'on_page_link_click',
            'elementPlacement': elementPlacement(elementTarget),
            'elementClicked': elementClicked(elementTarget),
            'pageType': pageTypeValue,
            'pageUrl': window.location.href,
            'position': providerIndex(elementTarget),
            'linkText': linkText(elementTarget),
        };
        window.dataLayer.push(defaultData);

        console.log(defaultData);
    }

    // Takeover Banner header cta
    if (TAKEOVER_BANNER_HEADER_BTN) {
        TAKEOVER_BANNER_HEADER_BTN.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                const defaultData = {
                    'event': 'sponsored_click_out',
                    'elementClicked': 'takeover banner',
                    'destinationType': destinationType(elementTarget),
                    'elementPlacement': 'header',
                    'elementName': 'Header Takeover banner',
                    'serviceProvider': null,
                    'pageType': context,
                    'href': ctaUrl(elementTarget),
                };
                window.dataLayer.push(defaultData);

                console.log(defaultData);
            });
        });
    }

    // Trending Banner cta
    if (TRENDING_BANNER_BTN) {
        TRENDING_BANNER_BTN.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                const defaultData = {
                    'event': 'sponsored_click_out',
                    'elementClicked': 'trending banner',
                    'destinationType': destinationType(elementTarget),
                    'elementPlacement': 'header',
                    'elementName': 'Trending banner link',
                    'serviceProvider': null,
                    'pageType': context,
                    'href': ctaUrl(elementTarget),
                };
                window.dataLayer.push(defaultData);

                console.log(defaultData);
            });
        });
    }

    // Takeover Banner footer cta
    if (TAKEOVER_BANNER_FOOTER_BTN) {
        TAKEOVER_BANNER_FOOTER_BTN.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                const defaultData = {
                    'event': 'sponsored_click_out',
                    'elementClicked': 'takeover banner',
                    'destinationType': destinationType(elementTarget),
                    'elementPlacement': 'footer',
                    'elementName': 'Footer Takeover banner',
                    'serviceProvider': null,
                    'pageType': context,
                    'href': ctaUrl(elementTarget),
                };
                window.dataLayer.push(defaultData);

                console.log(defaultData);
            });
        });
    }

    // Banner cta box within content
    if (BANNER_CTA) {
        BANNER_CTA.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                window.dataLayer.push({
                    'event': 'cta_click_out',
                    'elementPlacement': 'tip box banner cta',
                    'elementClicked': 'text link',
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                    'serviceProvider': productBrand || '',
                    'productCategory': productCategoryValue,
                    'activePromotion': checkActivePromotion(),
                    'position': 'single',
                    'linkText': linkText(elementTarget),
                });

                console.log({
                    'event': 'cta_click_out',
                    'elementPlacement': 'tip box banner cta',
                    'elementClicked': 'text link',
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                    'serviceProvider': productBrand || '',
                    'productCategory': productCategoryValue,
                    'activePromotion': checkActivePromotion(),
                    'position': 'single',
                    'linkText': linkText(elementTarget),
                });
            });
        });
    }

    // Medicare Banner cta box within content
    if (MEDICARE_BANNER) {
        MEDICARE_BANNER.forEach(btn => {
            btn.addEventListener('click', (e) => {
                const elementTarget = e.currentTarget;
                window.dataLayer.push({
                    'event': 'cta_click_out',
                    'elementPlacement': 'medicare banner',
                    'elementClicked': 'cta',
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                    'serviceProvider': productBrand || '',
                    'productCategory': productCategoryValue,
                    'activePromotion': checkActivePromotion(),
                    'position': 'single',
                    'linkText': linkText(elementTarget),
                });

                console.log({
                    'event': 'cta_click_out',
                    'elementPlacement': 'medicare banner',
                    'elementClicked': 'cta',
                    'pageType': pageTypeValue,
                    'href': ctaUrl(elementTarget),
                    'serviceProvider': productBrand || '',
                    'productCategory': productCategoryValue,
                    'activePromotion': checkActivePromotion(),
                    'position': 'single',
                    'linkText': linkText(elementTarget),
                });
            });
        });
    }

    function setupSearchForm(form, fieldSelector) {
        if (form) {
            form.addEventListener('submit', () => {
                const defaultData = {
                    'event': 'site_search',
                    'searchTerm': document.querySelector(fieldSelector).value || '',
                };
                window.dataLayer.push(defaultData);

                console.log(defaultData);
            });
        }
    }

    // Setup Desktop Search Form
    setupSearchForm(DESKTOP_SEARCH_FORM, '.search-field');

    // Setup Mobile Search Form
    setupSearchForm(MOBILE_SEARCH_FORM, '.mobile-search-field');

    // Referral Call click
    document.addEventListener('click', function (e) {
        const ctaClickout = e.target.closest(REFERRAL_CTA_SELECTOR);
        const callClickout = e.target.closest(REFERRAL_CALL_SELECTOR);
        const internalClickout = e.target.closest(INTERNAL_LINKS_SELECTOR);
        const externalClickout = e.target.closest(EXTERNAL_LINKS_SELECTOR);
        const onPageClickout = e.target.closest(ON_PAGE_LINKS_SELECTOR);

        if (ctaClickout) {
            triggerClickOutAction(ctaClickout);
        }

        if (callClickout) {
            triggerCallClickOutAction(callClickout);
        }

        if (internalClickout) {
            triggerInternalExternalClick(internalClickout, 'internal');
        }

        if (externalClickout) {
            triggerInternalExternalClick(externalClickout, 'external');
        }

        if (onPageClickout) {
            triggerOnPageClick(onPageClickout);
        }
    });
}

export default function init() {
    triggerEventTracking();
}
