
function activateTextToSpeech()
{
    let textToSpeak = '';
    const textToSpeechElements = document.querySelectorAll('.text-to-speech'),
        btns = document.querySelectorAll('#text-to-speech'),
        pipe = document.querySelector('.text-speech-pipe');

    if ('speechSynthesis' in window && /Android/i.test(navigator.userAgent) !== true) {
        const synth = window.speechSynthesis;

        if (typeof textToSpeechElements !== 'undefined' && textToSpeechElements !== null) {
            for (let i = 0; i < textToSpeechElements.length; i++) {
                textToSpeak += ' '+textToSpeechElements[i].innerText+'.';
            }
        }

        synth.cancel();
        const msg = new SpeechSynthesisUtterance(textToSpeak);
        msg.rate = 0.85;
        msg.volume = 1;
        msg.pitch = 1;

        if (typeof btns !== 'undefined' && btns !== null) {
            for (let i = 0; i < btns.length; i++) {
                btns[i].classList.add('show');
                btns[i].addEventListener('click', function () {
                    const btnText = this.querySelector('span');
                    if (!this.classList.contains('active') && !this.classList.contains('running')) {
                        this.classList.add('active', 'running');
                        btnText.textContent = 'Pause';
                        synth.speak(msg);
                        msg.onerror = (event) => {
                            console.error(
                                `An error has occurred with the speech synthesis: ${event.error}`,
                            );
                        };
                        msg.onend = () => {
                            this.classList.remove('active', 'running');
                            btnText.textContent = 'Listen';
                            synth.cancel();
                        };
                    } else if (!this.classList.contains('active') && this.classList.contains('running')) {
                        this.classList.add('active');
                        btnText.textContent = 'Pause';
                        synth.resume();
                    } else {
                        btnText.textContent = 'Resume';
                        this.classList.remove('active');
                        synth.pause();
                    }
                });
            }
        }
    } else {
        for (let i = 0; i < btns.length; i++) {
            btns[i].style.display = 'none';
        }
        if(pipe !== null){
            pipe.style.display = 'none';
        }
        console.log('no support');
    }
}


export default function init()
{
    activateTextToSpeech();
}
