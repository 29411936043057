const
    PAGETYPE_FIELD = document.getElementById('pagetype'),
    PROVIDER_SELECTOR = document.querySelectorAll('.product-fieldset');


function triggerProviderTracking() {
    const pageTypeValue = PAGETYPE_FIELD ? PAGETYPE_FIELD.value : 'generic';

    // Get Index of CTA
    function providerIndex($element) {
        return $element.dataset.order || 'single'
    }

    // Get Product Name of CTA
    function productName($element) {
        return $element.dataset.productname || null;
    }

    const listingData = [];

    PROVIDER_SELECTOR.forEach(provider => {
        listingData.push({
            'event': 'provider_listing_position',
            'position': providerIndex(provider),
            'pageType': pageTypeValue,
            'serviceProvider': productName(provider),
        });

        console.log({
            'event': 'provider_listing_position',
            'position': providerIndex(provider),
            'pageType': pageTypeValue,
            'serviceProvider': productName(provider),
        });
    });

    window.dataLayer.push(...listingData);
}

export default function init() {
    triggerProviderTracking();
}
