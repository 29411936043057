function triggerTracking() {
    window.addEventListener('click_pixel_fired', function (evt) {
        // Update subId for Lytics
        if (document.getElementsByName('subId').length > 0) {
            document.getElementsByName('subId')[0].value = evt.detail.s1;
        }
    });
}

export default function init() {
    document.addEventListener('DOMContentLoaded', function () {
        triggerTracking();
    });
}
