function collapsedContent() {
    const collapse = document.getElementsByClassName('collapsable');
    let i;

    if (collapse) {
        for (i = 0; i < collapse.length; i++) {
            collapse[i].addEventListener('click', function () {
                this.classList.toggle('active');
                var content = this.nextElementSibling;
                var tableWrapper = content.querySelector('.table-wrapper');
            
                if (content.style.maxHeight) {
                    content.style.maxHeight = null;
                } else {
                    if (tableWrapper) {
                        var tableWrapperHeight = tableWrapper.scrollHeight;
                        content.style.maxHeight = tableWrapperHeight + content.scrollHeight + 'px';
                    } else {
                        content.style.maxHeight = content.scrollHeight + 'px';
                    }
                }
            })
        }
    }
}

export default function init() {
    collapsedContent();
}
