// Main Header nav - Mobile toggle
const el = document.querySelector('.mobile-menu-trigger');
el.onclick = () => document.querySelector('.main-menu').classList.toggle('display');

// Toggle active class - Mobile nav
const menuParents = document.querySelectorAll('.main-menu .menu-item-has-children > input');

export default function init() {
    console.log('Nav inside component.');
    for (let menuParent of menuParents) {
        let element = menuParent.closest('.menu-item-has-children');
        menuParent.onclick = () => setActiveClass(element);
    }

    // Add class to menus with columns
    const topLevels = document.querySelectorAll('.primary-header-menu ul.main-menu li.top-level');

    for (let topLevel of topLevels) {
        const subMenu = topLevel.querySelector(':scope > ul.sub-menu');

        if (subMenu.querySelectorAll('li.menu-item-has-children').length === 0) {
            subMenu.classList.add('not-grandparent');
        } else {
            subMenu.classList.add('is-grandparent');
        }
    }
}

function setActiveClass(element) {
    element.classList.toggle('active');
}

// Toggle class on Desktop Nav
const navItems = document.querySelectorAll('.main-menu .nav');

for (let navItem of navItems) {
    let navElement = navItem.closest('.menu-item-has-children');
    navItem.addEventListener('mouseenter', () => {
        navElement.classList.add('animate-caret');
    }, {passive: true})
    navItem.addEventListener('mouseleave', () => {
        navElement.classList.remove('animate-caret');
    }, {passive: true});
}
