
import authorOrder from './components/author-order';
import nav from './components/nav';
import addUrlParams from './components/add-url-params';
import youtubeSchema from './components/youtube-schema';
import triggerEventTracking from './components/analytics/cta-events';
import triggerLinkTracking from './components/analytics/link';
import triggerProviderTracking from './components/analytics/providers';
import triggerSponsorTracking from './components/analytics/banners';
import triggerTracking from './components/tracking';
import activateTextToSpeech from './components/text-to-speech';
import collapsedContent from './components/collapsable-content';

authorOrder();
nav();
addUrlParams();
youtubeSchema();
triggerEventTracking();
triggerLinkTracking();
triggerProviderTracking();
triggerSponsorTracking();
triggerTracking();
activateTextToSpeech();
collapsedContent();
