function triggerLinkTracking()
{

    const avoidElements = [
        '#takeover-notification-banner a.btn',
        '#takeover-notification-banner-footer a.btn',
        '.banner-cta a',
        '#trending-banner a',
        '[data-ga="social-share"]',
        '[data-ga="related-article"]',
        '[data-ga="referral-cta"]',
        '[data-ga="referral-call"]',
    ];

    const linkedElements = {
        'disclaimer': {
            'disclaimer-link': '.global-header .header-disclaimer',
        },
        'header': {
            'logo': '.global-header .brand',
            'nav': '.global-header .main-menu',
            'subnav': '.global-header .ref-right-menu',
        },
        'hero': {
            'author': '.page-meta .author-link',
            'expert': '.page-meta .expert-name',
            'social-links': '.page-meta .meta-icons',
        },
        'footer': {
            'footer_logo': '.global-footer .brand',
            'footer_social': '.global-footer .social',
            'footer_nav': '.global-footer .nav-menu',
            'footer_subnav': '.global-footer .nav-alternate',
        },
        'content': {
            'content-link': '.main-content',
            'top-card-anchor': '.featured-tiles .learn-more-link',
            'anchor-link': '.anchor-link-content .anchor-content',
            'faq': '.faqs .accordion-title',
            'product-anchor': '.product-anchor-list',
            'citations': '.citation-wrapper .citation',
        },
    };

    const isolatedElements = [
        'anchor-link',
        'faq',
        'product-anchor',
        'citations',
        'top-card-anchor',
    ];

    // const isParentNode = (node, parentNode) => {
    //     return node === parentNode ? false : parentNode.contains(node);
    // }

    const isExternalRegex = (function () {
        const domainRe = /https?:\/\/([^/]+)/i;

        return (url) => {
            if (!url || url.startsWith('tel:') || url.startsWith('mailto:') || url === '#') {
                return false;
            }

            // Extract domain from URL
            const getDomain = (link) => {
                const match = domainRe.exec(link);
                return match ? match[1] : null;
            };

            const currentDomain = getDomain(location.href);
            const linkDomain = getDomain(url);

            return linkDomain !== null && linkDomain !== currentDomain;
        };
    })();

    const setLinkTrackingAttr = (element, location, elementClicked, order, isolated = '', linkType = '') => {
        if (element.getAttribute('data-elementPlacement') === null || isolated !== '') {
            element.setAttribute('data-elementPlacement', location);
        }
        if (element.getAttribute('data-elementClicked') === null || isolated !== '') {
            element.setAttribute('data-elementClicked', elementClicked);
        }
        if (element.getAttribute('data-order') === null || isolated !== '') {
            element.setAttribute('data-order', order);
        }
        if (linkType !== '' && isolated !== '') {
            element.setAttribute('data-link-type', linkType);
        } else {
            element.setAttribute('data-link-type', isExternalRegex(element.href) ? 'external' : 'internal');
        }
    }

    const setupLinkTrackingAttr = (element, location, elementClicked) => {
        if (element.nodeName === 'A') {
            const checkStatus = element.getAttribute('data-skip-tracking');
            if (typeof checkStatus === 'undefined' || checkStatus === null) {
                setLinkTrackingAttr(element, location, elementClicked, 'single');
            }
        } else if (elementClicked === 'faq' || elementClicked === 'citations' || elementClicked === 'top-card-anchor') {
            let linkCount = 1;
            element.forEach(function (clickableElement) {
                const checkStatus = clickableElement.getAttribute('data-skip-tracking');
                if (typeof checkStatus === 'undefined' || checkStatus === null) {
                    setLinkTrackingAttr(clickableElement, location, elementClicked, linkCount, 'isolated', 'on-page');
                    linkCount++;
                }
            });
        } else {
            const links = element.querySelectorAll('a');
            let linkCount = 1,
                onPageLinkCount = 1;
            links.forEach(function (link) {
                const checkStatus = link.getAttribute('data-skip-tracking');
                if (typeof checkStatus === 'undefined' || checkStatus === null) {
                    if (isolatedElements.indexOf(elementClicked) !== -1) {
                        setLinkTrackingAttr(link, location, elementClicked, onPageLinkCount, 'isolated', 'on-page');
                        onPageLinkCount++;
                    } else {
                        if (
                            link.classList.contains('brand') === false ||
                            link.parentNode.classList.contains('social_items') === false
                        ) {
                            setLinkTrackingAttr(link, location, elementClicked, linkCount);
                            if (location !== 'in-content') {
                                if (link.getAttribute('data-order') === null) {
                                    link.setAttribute('data-order', linkCount);
                                }
                            }
                            linkCount++;
                        }
                    }
                }
            });
        }
    }

    avoidElements.forEach(function (elements) {
        const queriedElements = document.querySelectorAll(elements);
        if (typeof queriedElements !== 'undefined' && queriedElements !== null) {
            queriedElements.forEach(function (element) {
                element.setAttribute('data-skip-tracking', 'true');
            });
        }
    });

    for (const location in linkedElements) {
        const linkElements = linkedElements[location];
        for (const elementClicked in linkElements) {
            let element = '';
            if (elementClicked === 'citations' || elementClicked === 'faq' || elementClicked === 'top-card-anchor') {
                element = document.querySelectorAll(linkElements[elementClicked]);
            } else {
                element = document.querySelector(linkElements[elementClicked]);
            }
            if (typeof element !== 'undefined' && element !== null) {
                setupLinkTrackingAttr(element, location, elementClicked);
            }
        }
    }
}

export default function init()
{
    triggerLinkTracking();
}
